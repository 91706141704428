import './App.css';
import { Route, Routes } from 'react-router-dom';
import VerificationOtp from './VerificationOtp';
import { Toaster } from 'react-hot-toast';
import { socket, SocketContext } from './socket';
import 'react-photo-view/dist/react-photo-view.css';

function App() {
  return (
    <SocketContext.Provider value={socket}>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Routes>
        <Route path="/:compName/:numberKey" element={<VerificationOtp />} />
      </Routes>
    </SocketContext.Provider>
  );
}

export default App;
