/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { FiLoader } from "react-icons/fi";
import { FaLinkSlash } from "react-icons/fa6";
import { Lock, Smartphone, AlertCircle } from 'lucide-react';
import Timer from './Timer';
import VerificationInput from './VerificationInput';
import ChatApp from './ChatApp';
import { set } from 'date-fns/esm';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { getFirebaseToken, onForegroundMessage } from './firebase';

export const config = {
  Accept: 'application/json',
  headers: {
    // Authorization: `Bearer ${token}`,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
};
function Verification() {
  const { compName, numberKey } = useParams();
  const [compId, setCompanyId] = useState(null); 
  const [cId, setConversationId] = useState(null); 
  const [loading, setLoading] = useState(true);
  const [isBlocked, setisBlocked] = useState(false);
  const [verificationCode, setVerificationCode] = useState('null');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [chatAppVerified, setChatAppVerified] = useState(localStorage.getItem('isVerified') === 'true' ? true : false);
  const [companyName, setCompanyName] = useState('');
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [error, setError] = useState(null);
  const [verifyLoading, setVerifyLoading] = useState(false);

  useEffect(() => {
    handlegetCompanyandContactData();
    requestNotificationPermission();
  }, []);
  const [permission, setPermission] = useState(Notification.permission);

  const requestNotificationPermission = () => {
    if (permission === "default") {
      Notification.requestPermission().then((result) => {
        setPermission(result);
      });
    }
  };
  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken) => {
        if (firebaseToken) {
          handleSendDeviceToken(firebaseToken);
        }
      })
      .catch((err) => console.error('An error occured while retrieving firebase token. ', err));
  };

  const handleSendDeviceToken = async (val) => {
    const formData = {
      token: val,
      company_id: localStorage.getItem('compid') ?? compId,
      is_convo: true,
    };
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/web-notification/receive-web-token/${localStorage.getItem('covoid') ?? cId}`, formData, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      }
    })
  };

  const handlegetCompanyandContactData = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/company/fetch-company-and-conversation/${compName}/${numberKey}`, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      }
    })
    setCompanyName(response?.data?.data?.company_data?.name);
    setProfilePhoto(response?.data?.data?.company_data?.profile_photo_url);
    setPhoneNumber(response?.data?.data?.contact_data?.phone);
    setCompanyId(response?.data?.data?.contact_data?.company_id);
    setConversationId(response?.data?.data?.contact_data?.conversation_id);
    localStorage.setItem('compid',response?.data?.data?.contact_data?.company_id);
    localStorage.setItem('covoid',response?.data?.data?.contact_data?.company_id);
    setTimeout(() => {
      handleGetFirebaseToken();
    }, 2000);
    setLoading(false);
  }
  const handleCodeChange = (index, value) => {
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);
      const currentLength = newCode.filter(Boolean).length;
      const previousLength = verificationCode.filter(Boolean).length;
      if (currentLength === 5 && previousLength === 6) {
        setError(null);
      }

      if (value && index < 5) {
        const nextInput = document.getElementById(`code-${index + 1}`);
        nextInput?.focus();
      }
    }
  };

  const handleGenerateOTP = async () => {
    setIsTimerActive(true);
    setVerificationCode(Array(6).fill(''));
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/chat/generate-secure-chat-otp/${cId}`, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      }
    })
    if (response?.data?.status === 'success') {
      setOtpSent(true);
    } else {
      toast.error('Failed to send OTP!')
    }
  };
  const handleMatchOTP = async () => {
    setVerifyLoading(true);
    const formdata = {
      otp: verificationCode.join(''),
    }
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/chat/verify-secure-chat-otp/${cId}`, formdata, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      }
    })
    if (response?.data?.status === 'success') {
      setChatAppVerified(true);
      localStorage.setItem('isVerified', 'true');
    } else {
      setChatAppVerified(false);
      setError('Invalid verification code. Please try again.');
    }
    setVerifyLoading(false);
  };

  const handleResendOTP = () => {
    if (!isTimerActive) {
      handleGenerateOTP();
    }
  };

  const handleTimerComplete = () => {
    setIsTimerActive(false);
  };
  if (isBlocked === true) {
    return (
      <div className="w-full h-[100vh] items-center justify-center flex flex-col  bg-gradient-to-br from-blue-50 to-indigo-100">
        <FaLinkSlash className="text-[80px] mb-[20px]" />
        <span className="text-[30px] medium">
          Chat Expired
        </span>
      </div>
    );
  }
  if (loading) {
    return (
      <div className="w-full h-[100vh] items-center justify-between flex flex-col bg-gradient-to-br from-blue-50 to-indigo-100">
        <FiLoader size={50} className="animate-spin mt-[47vh]" />
        <div className='flex justify-center items-center relative text-[20px] pb-2 h-[1.5rem] w-full rounded-b-[8px] mb-[5vh]'>
          <span className="text-[#535355]"> Powered by</span>
          <div
            className='flex items-center ml-1 cursor-pointer'
            onClick={() => window.open('https://www.salescaptain.com')}
          >
            <img src="https://s3.us-east-2.amazonaws.com/sc-prod-embedded.salescaptain.com/resources/2023-07-22/sales_captain_1690015901642.png" className=" h-[18px]" alt="" />
            <span
              className="font-semibold ml-1"
            >
              SalesCaptain
            </span>
          </div>
        </div>

      </div>
    );
  }
  function formatUSPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  }

  const formattedPhoneNumber = formatUSPhoneNumber(phoneNumber);
  return (
    <div className="h-[100vh] overflow-hidden bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center">
      {chatAppVerified ? (
        <ChatApp
          companyName={companyName}
          profilePhoto={profilePhoto}
          setChatAppVerified={setChatAppVerified}
          cId={cId}
          compId={compId}
        />
      ) : (
        <div className="bg-white rounded-2xl shadow-xl p-8 m-2 w-full max-w-md">
          <div className="flex flex-col items-center mb-8">
            <div className="bg-indigo-100 p-3 rounded-full mb-4">
              <Lock className="w-8 h-8 text-indigo-600" />
            </div>
            <h2 className="text-2xl font-bold text-gray-800">Verification Code</h2>
            <p className="text-gray-500 text-center mt-2">
              {otpSent
                ? 'Enter the verification code sent to your device'
                : 'Click generate to receive your verification code'}
            </p>
          </div>

          <div className="flex items-center justify-center gap-2 mb-6 bg-gray-50 p-4 rounded-lg">
            <Smartphone className="w-5 h-5 text-gray-600" />
            <span className="text-gray-600 font-medium">{formattedPhoneNumber}</span>
          </div>
          {error && (
            <div className="mb-6 p-3 bg-red-50 border border-red-100 rounded-lg flex items-center gap-2 text-red-600">
              <AlertCircle className="w-5 h-5 flex-shrink-0" />
              <p className="text-sm">{error}</p>
            </div>
          )}
          {otpSent && (
            <VerificationInput
              verificationCode={verificationCode}
              onChange={handleCodeChange}
            />
          )}

          {!otpSent ? (
            <button
              onClick={handleGenerateOTP}
              className="w-full bg-indigo-600 text-white py-3 rounded-lg font-semibold hover:bg-indigo-700 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Generate OTP
            </button>
          ) : (
            <div className="text-center">
              <button
                onClick={() => { handleMatchOTP(); }}
                disabled={verificationCode?.join('')?.length !== 6 || error || verifyLoading}
                className="w-[70%] mx-auto mb-2 disabled:!bg-slate-300 disabled:!text-slate-800 bg-indigo-600 text-white py-3 rounded-lg font-semibold hover:bg-indigo-700 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>


              <p className="text-gray-500 mb-4">
                Didn't receive the code?{' '}
                <button
                  onClick={handleResendOTP}
                  disabled={isTimerActive}
                  className={`font-medium ${isTimerActive
                    ? 'text-gray-400 cursor-not-allowed'
                    : 'text-indigo-600 hover:text-indigo-700'
                    }`}
                >
                  Resend
                </button>
              </p>
              {isTimerActive && (
                <p className="text-sm text-gray-500">
                  Resend available in{' '}
                  <Timer
                    duration={120}
                    onComplete={handleTimerComplete}
                    isActive={isTimerActive}
                  />
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Verification;
